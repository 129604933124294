// import { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import "./StaticWelcomPage.css";
// import { useState } from "react";
// import LanguageContext from "../../context/LanguageProvider";
// import { Link } from "react-router-dom";
// import { navigationToLInks } from "../Home/Data";
// import { useEffect, useState } from "react";
const StaticWelcomPage = () => {
  // const { changeLanguage } = useContext(LanguageContext);
  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (count < 98) {
  //       setCount(count + 2);
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 100);

  //   return () => clearInterval(intervalId);
  // }, [count]);

  function goToWebsite(website) {
    window.open(website, "_blank");
  }

  return (
    <div className="relative w-full">
      <div className="bg-[#252C34]  h-6 w-full"></div>
      <div className="bg-white h-16  w-full py-2 md:px-12 lg:px-[112px] px-4 flex justify-between items-center">
        <img src="workeyLogo.png" alt="" className="h-[40px]" />
        <button
          onClick={() =>
            goToWebsite(
              "https://docs.google.com/forms/d/e/1FAIpQLSczg_aGAqBg18Ech9jS5maPHa8uLb7DqnqpryuVo6Hg6l7puw/viewform?usp=header"
            )
          }
          className="px-4 py-2 md:py-4 button_soon md:px-8"
        >
          <span className="">Request Demo</span>
        </button>
      </div>

      <div className="2xl:w-[537px]  2xl:h-[689px] md:w-[302px] md:h-[387px] w-[241px] h-[310px]  lg:w-[429px] lg:h-[551px] absolute bottom-0 left-[20%] md:left-[25%] xl:left-0 z-20">
        <img src="/mobile-static.svg" alt="" className="w-full h-full" />
      </div>
      <div className="bg-[url('/staticHome.png')] pt-0 xl:pt-[30px] relative bg-no-repeat bg-cover 2xl:px-[112px] px-4 md:px-[50px] lg:px-[80px] min-h-[calc(100vh-5.5rem)]">
        <div className="grayscale-overlay"></div>
        <div className="flex flex-col-reverse min-h-[calc(100vh-5.5rem)] gap-16 xl:justify-center xl:flex-row pt-8 xl:pt-0 lg:pb-[551px] md:pb-[387px] pb-[310px]  xl:pb-0">
          <div className="flex flex-col items-center justify-center gap-8 xl:ml-auto">
            <img src="/Frame 335458 (1).png" alt="" className="" />
            <div className="flex items-center gap-2">
              <img
                src="/loader-s.gif"
                alt=""
                className="h-6 w-[15rem] md:w-[25rem]"
              />
              <span className="z-20 text-white">98%</span>
            </div>
            <div className="z-20 flex items-center gap-2">
              <span className="w-3 h-3 rounded-full bg-[#04C946]"></span>
              <span className="text-3xl font-normal text-white">
                Coming Soon
              </span>
              <span className="w-3 h-3 rounded-full bg-[#04C946]"></span>
            </div>
            <div className="text-sm md:text-base lg:text-xl descrip">
              Workey is Saudi Arabia&apos;s premier business hub connecting
              businesses with modern workspaces and essential services,
              simplifying your journey from start-up to success
            </div>
            <div>
              <div className="w-full services_fast_nav">
                <div
                  className={`w-full gap-6 grid grid-cols-2 md:grid-cols-3 pb-4`}
                >
                  <div
                    className={`relative xl:w-[224px] xl:h-[140px] md:w-[168px] h-[105px] w-[158px]  overflow-hidden transition-all duration-300 shadow-md Service_catigory_card rounded-xl hover:shadow-lg cursor-pointer group border border-[#89D98B] md:border-green-950  z-50 md:hover:border-[#89D98B]`}
                  >
                    <div className={`absolute inset-0 bg-gray-200`}>
                      <img
                        src="/longterm-s.jpeg"
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-300 bg-black md:bg-opacity-40 bg-opacity-60 md:group-hover:bg-opacity-60">
                      <div className="w-16 h-16 mb-2 text-center text-white md:transition-all md:duration-300 md:transform md:-translate-y-full md:opacity-0 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        <img
                          src="/longterm.svg"
                          alt=""
                          className="w-12 h-12 mx-auto mt-2"
                        />
                      </div>
                      <p className="font-bold text-green-500 md:transition-all md:duration-300 md:transform md:translate-y-full md:opacity-100 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        long term
                      </p>
                    </div>
                  </div>
                  <div
                    className={`relative xl:w-[224px] xl:h-[140px] md:w-[168px] h-[105px] w-[158px]  overflow-hidden transition-all duration-300 shadow-md Service_catigory_card rounded-xl hover:shadow-lg group cursor-pointer border border-[#89D98B] md:border-green-950  z-50 md:hover:border-[#89D98B]`}
                  >
                    <div className={`absolute inset-0 bg-gray-200`}>
                      <img
                        src="/shared-s.jpeg"
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-300 bg-black md:bg-opacity-40 bg-opacity-60 md:group-hover:bg-opacity-60">
                      <div className="text-center text-white md:transition-all md:duration-300 md:transform md:-translate-y-full md:opacity-0 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        <img
                          src="/sharedoffice.svg"
                          alt=""
                          className="w-16 h-16 mx-auto mb-2"
                        />
                      </div>
                      <p className="font-bold text-green-500 md:transition-all md:duration-300 md:transform md:translate-y-full md:opacity-100 group-hover:translate-y-0 group-hover:opacity-100">
                        shared office
                      </p>
                    </div>
                  </div>
                  <div
                    className={`relative xl:w-[224px] xl:h-[140px] md:w-[168px] h-[105px] w-[158px] overflow-hidden transition-all duration-300 shadow-md Service_catigory_card rounded-xl hover:shadow-lg group cursor-pointer border border-[#89D98B] md:border-green-950  z-50 md:hover:border-[#89D98B]`}
                  >
                    <div className={`absolute inset-0 bg-gray-200`}>
                      <img
                        src="/private-s.jpeg"
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-300 bg-black md:bg-opacity-40 bg-opacity-60 md:group-hover:bg-opacity-60">
                      <div className="text-center text-white md:transition-all md:duration-300 md:transform md:-translate-y-full md:opacity-0 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        <img
                          src="/privateoffice.svg"
                          alt=""
                          className="w-16 h-16 mx-auto mb-2"
                        />
                      </div>
                      <p className="font-bold text-green-500 md:transition-all md:duration-300 md:transform md:translate-y-full md:opacity-100 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        private office
                      </p>
                    </div>
                  </div>
                  <div
                    className={`relative xl:w-[224px] xl:h-[140px] md:w-[168px] h-[105px] w-[158px]  overflow-hidden transition-all duration-300 shadow-md Service_catigory_card rounded-xl hover:shadow-lg group cursor-pointer border border-[#89D98B] md:border-green-950  z-50 md:hover:border-[#89D98B]`}
                  >
                    <div className={`absolute inset-0 bg-gray-200`}>
                      <img
                        src="/meeting-s.jpeg"
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-300 bg-black md:bg-opacity-40 bg-opacity-60 md:group-hover:bg-opacity-60">
                      <div className="text-center text-white md:transition-all md:duration-300 md:transform md:-translate-y-full md:opacity-0 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        <img
                          src="/meetingroom.svg"
                          alt=""
                          className="w-16 h-16 mx-auto mb-2"
                        />
                      </div>
                      <p className="font-bold text-green-500 md:transition-all md:duration-300 md:transform md:translate-y-full md:opacity-100 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        meeting rooms
                      </p>
                    </div>
                  </div>
                  <div
                    className={`relative xl:w-[224px] xl:h-[140px] md:w-[168px] h-[105px] w-[158px]  overflow-hidden transition-all duration-300 shadow-md Service_catigory_card rounded-xl hover:shadow-lg group cursor-pointer  border border-[#89D98B] md:border-green-950  z-50 md:hover:border-[#89D98B]`}
                  >
                    <div className={`absolute inset-0 bg-gray-200`}>
                      <img
                        src="/event-s.jpeg"
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-300 bg-black md:bg-opacity-40 bg-opacity-60 md:group-hover:bg-opacity-60">
                      <div className="text-center text-white md:transition-all md:duration-300 md:transform md:-translate-y-full md:opacity-0 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        <img
                          src="/eventspace.svg"
                          alt=""
                          className="w-16 h-16 mx-auto mb-2"
                        />
                      </div>
                      <p className="font-bold text-green-500 md:transition-all md:duration-300 md:transform md:translate-y-full md:opacity-100 md:group-hover:translate-y-0 md:group-hover:opacity-100">
                        event space
                      </p>
                    </div>
                  </div>
                  <div
                    className={`xl:w-[224px] xl:h-[140px] md:w-[168px] h-[105px] w-[158px]  cursor-pointer z-10`}
                  >
                    <img
                      src="/and-more.svg"
                      className="object-cover w-full h-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="absolute copy_rights bottom-1 z-30 right-[20%] md:right-[30%]  xl:right-[112px] lg:right-[35%] opacity-70">
        © 2024 Workey. All rights reserved.
      </p>
    </div>
  );
};

export default StaticWelcomPage;
